import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../styles/toolcard.css";
import { Link } from "react-router-dom";

const ToolCard = ({ heading, iconSvg, link }) => {
  return (
    <div className="tool-card1">
      <Link to={`/tools/${link}`} className="tool-card-link1">
        <div className="tool-card-icon1">
          <img src={iconSvg} alt={`${heading} avatar`} />
        </div>
      </Link>
      <h3 className="tool-card-heading1">{heading}</h3>
    </div>
  );
};

ToolCard.propTypes = {
  heading: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string, // Add this line
};

export default ToolCard;
