import { Link } from "react-router-dom";

const ToolCard = ({ heading, paragraph, link, iconSvg }) => {
  return (
    <Link to={`/tools/${link}`}>
      <div className="bg-[#1c2331] rounded-xl flex flex-col md:flex-row p-4 md:p-6 space-y-4 md:space-y-0 md:space-x-4">
        {" "}
        <img
          src={iconSvg}
          alt=""
          style={{
            height: "55px",
            borderRadius: "50%",
            padding: "9px",
            border: "2px solid #6cd97e",
          }}
        />
        <div className="flex-grow flex flex-col justify-center">
          <p className="text-white font-semibold text-sm truncate">{heading}</p>
          <p
            className="text-gray-400 text-sm md:text-sm mt-1 max-h-16 overflow-hidden text-ellipsis"
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {paragraph}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ToolCard;
