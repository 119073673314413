import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo_imgs from "../assets/mainLogo.webp";
import { Link } from "react-router-dom";
import { IoMdNotificationsOutline } from "react-icons/io";
import Gravatar from "react-gravatar";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { FaHome } from "react-icons/fa";
import { AiFillWechat } from "react-icons/ai";
import { SlSocialDribbble } from "react-icons/sl";
import { IoMdTrophy } from "react-icons/io";
import { MdOutlineCollectionsBookmark, MdOutlineExplore } from "react-icons/md";
import { TbTools } from "react-icons/tb";
import { RxHamburgerMenu } from "react-icons/rx";
import { Sidebar } from "./Sidebar";
import Dropdown_Menu from "../Account_Component/dropdownMenu";
import NotificationDropdown from "./NotificationDropdown";
import "../styles/MainHeader.css";
import { updateNotification } from "../features/userSlice";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuth, imageUrl } = useSelector((state) => state.user);
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const { userName, notificationCount } = useSelector((state) => state.user);
  const hash = CryptoJS.MD5(userName).toString();
  const dropdownRef = useRef(null);
  const notificationDropdownRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const openNotificationDropdown = () => {
    dispatch(updateNotification(0));
    setShowNotifications(true);
  };
  const closeNotificationDropDown = () => {
    setShowNotifications(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }

      if (
        showNotifications &&
        notificationDropdownRef.current &&
        !notificationDropdownRef.current.contains(event.target)
      ) {
        setShowNotifications(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown, showNotifications]);

  const handleMenuLinkClick = (path) => {
    navigate(path);
    setActiveLink(path);
    handleMenuClick();
  };

  const handleProfileDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  return (
    <>
      <div className="main_header_component">
        <header className="header_component">
          <div className="kjndhsjv flex gap-4 items-center w-[90%] sm:w-[60%] md:w-[45%] lg:w-[47%] xl:w-[35%]">
            <div className="looo">
              <Link to="/">
                <img src={logo_imgs} alt="Main logo" className="mainLogoIP" />
              </Link>
            </div>
            <ul className="najvbh">
              <li>
                {isAuth ? (
                  <Link
                    to="/"
                    className={`navank ${activeLink === "/" ? "active" : ""}`}
                    onClick={() => setActiveLink("/")}
                  >
                    <FaHome size={22} className="yools" />
                    Home
                  </Link>
                ) : null}
              </li>
              <li>
                {isAuth ? (
                  <Link
                    to="/aichat/youai"
                    className={`navank ${activeLink === "/aichat/youai" ? "active" : ""}`}
                    onClick={() => setActiveLink("/aichat/youai")}
                  >
                    <AiFillWechat size={22} className="yools" />
                    AI Chat
                  </Link>
                ) : null}
              </li>
              <li>
                {isAuth ? (
                  <Link
                    to="/social"
                    className={`navank ${
                      activeLink === "/social" ? "active" : ""
                    }`}
                    onClick={() => setActiveLink("/social")}
                  >
                    <SlSocialDribbble size={22} className="yools" />
                    Social
                  </Link>
                ) : null}
              </li>
              <li>
                {isAuth ? (
                  <Link
                    to="/collection"
                    className={`navank ${
                      activeLink === "/collection" ? "active" : ""
                    }`}
                    onClick={() => setActiveLink("/collection")}
                  >
                    <MdOutlineCollectionsBookmark size={22} className="yools" />
                    Collections
                  </Link>
                ) : null}
              </li>
              <li>
                {isAuth ? (
                  <Link
                    to="/tools"
                    className={`navank ${
                      activeLink === "/tools" ? "active" : ""
                    }`}
                    onClick={() => setActiveLink("/tools")}
                  >
                    <TbTools size={22} className="yools" />
                    AI Tools
                  </Link>
                ) : null}
              </li>
              <li>
                {isAuth ? (
                  <Link
                    to="/aichallenge"
                    className={`navank ${
                      activeLink === "/aichallenge" ? "active" : ""
                    }`}
                    onClick={() => setActiveLink("/aichallenge")}
                  >
                    <IoMdTrophy size={22} className="yools" />
                    AI Challenge
                  </Link>
                ) : null}
              </li>
            </ul>
          </div>

          <div className="md:w-auto flex gap-4 items-center">
            {location.pathname.includes("/social") && (
              <MdOutlineExplore
                size={34}
                className="explore_icon"
                onClick={() => navigate("/social/explore")}
              />
            )}
            <IoMdNotificationsOutline
              size={34}
              className="notification_icon relative inline-block"
              onClick={openNotificationDropdown}
            />
            {notificationCount > 0 && (
              <span className="absolute top-[19px] right-[92px] md:right-[170px] bg-red-500 text-white text-xs font-bold rounded-full w-4 h-4 flex items-center justify-center">
                {notificationCount}
              </span>
            )}
            {showNotifications && (
              <NotificationDropdown
                handleClose={closeNotificationDropDown}
                ref={notificationDropdownRef}
              />
            )}

            <div
              className="profile_sys"
              onClick={() => handleProfileDropdown()}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="heh"
                  className="user_profile_social"
                  title={userName}
                />
              ) : (
                <Gravatar
                  email={userName}
                  size={150}
                  default="identicon"
                  className="user_frien3"
                  hash={hash}
                />
              )}
              <div className="drop">
                {showDropdown && <Dropdown_Menu ref={dropdownRef} />}
              </div>
            </div>
            <RxHamburgerMenu
              className="menu"
              size={29}
              color="#fff"
              onClick={handleMenuClick}
              whileHover={{ scale: 1.1 }}
            />
          </div>
        </header>
      </div>
      {
        <Sidebar
          showMenu={showMenu}
          handleMenuClick={handleMenuClick}
          activeLink={activeLink}
          handleMenuLinkClick={handleMenuClick}
        />
      }
    </>
  );
};

export default Header;
