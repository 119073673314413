import React, { useState } from "react";
import { Canvas } from "@react-three/fiber";
import Experience from "./Experience";
import { FaPlay, FaPause } from "react-icons/fa"; // Font Awesome icons
import "../styles/AvatarViewer.css";

// Custom PlayPauseButton Component
const PlayPauseButton = ({ isPlaying, onClick }) => {
  return (
    <button onClick={onClick} className="button">
      {isPlaying ? <FaPause /> : <FaPlay />} {/* Toggle Icon */}
    </button>
  );
};

// Main AvatarViewer Component
const AvatarViewer = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    console.log("Toggling play/pause:", !isPlaying); // Debugging log
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="container">
      <Canvas shadows camera={{ position: [0, 0, 8], fov: 42 }}>
        <Experience isPlaying={isPlaying} setIsPlaying={setIsPlaying} />{" "}
        {/* Pass isPlaying as a prop */}
      </Canvas>

      {/* Custom PlayPauseButton */}
      <PlayPauseButton isPlaying={isPlaying} onClick={togglePlayPause} />
    </div>
  );
};

// Exporting AvatarViewer Component
export default AvatarViewer;
