import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { HashLoader } from "react-spinners"; // Import the ClipLoader from react-spinners
import "../styles/AIChallenge.css";
import image1 from "../assets/Images/FeaturedModels/block-1@2x.png";
import image2 from "../assets/Images/FeaturedModels/block-2@2x.png";
import image3 from "../assets/Images/FeaturedModels/block-3@2x.png";
import baseUrl from "../baseUrl";
import apiService from "../services/apiService";

const AIChallenge = () => {
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading
  const navigate = useNavigate(); // Initialize useNavigate

  // Fetch AI Challenge posts on component mount
  useEffect(() => {
    const fetchAiChallenges = async () => {
      try {
        const response = await apiService.get(
          `${baseUrl}/ai/get-ai-challenge-posts`
        );

        // Sort challenges by createdAt field, assuming newest challenges come first
        const sortedChallenges = response.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setChallenges(sortedChallenges);
      } catch (error) {
        console.error("Error fetching AI Challenge posts:", error);
      } finally {
        setLoading(false); // Set loading to false after the data is fetched
      }
    };

    fetchAiChallenges();
  }, []);

  const handleChallengeClick = (challenge) => {
    navigate("/AIChallengeDetail", { state: { challenge } }); // Use navigate instead of history.push
  };

  // Helper function to limit text length and add '...'
  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return `${text.substring(0, limit)}...`;
    }
    return text;
  };

  return (
    <div className="ai-challenge-container">
      <h1 className="ai-challenge-title">AI Challenge</h1>
      <p className="ai-challenge-subtitle">
        Model for generating text to image
      </p>

      {/* Ongoing Challenges Section */}
      <section className="ongoing-challenges-section">
        <h2 className="ongoing-challenges-title">Ongoing Challenges</h2>

        {/* Show spinner while loading */}
        {loading ? (
          <div className="spinner-conta">
            <HashLoader color="#6cd97e" size={60} />
          </div>
        ) : challenges.length === 0 ? (
          <div className="no-challenges">
            <p>No ongoing AI challenges at the moment.</p>
          </div>
        ) : (
          <div className="challenge-grid">
            {challenges.map((challenge) => (
              <div
                key={challenge._id}
                className="challenge-card"
                onClick={() => handleChallengeClick(challenge)}
              >
                {challenge.mediaUrls.length > 0 && (
                  <img
                    src={challenge.mediaUrls[0].url}
                    alt="Challenge"
                    className="challenge-image"
                  />
                )}
                <h3 className="challenge-name">Challenge</h3>
                <p className="challenge-description">
                  {/* Limit description to 100 characters */}
                  {truncateText(challenge.caption || "No Description", 150)}
                </p>
              </div>
            ))}
          </div>
        )}
      </section>

      {/* Upcoming Challenges Section (uses same class names) */}
      <section className="ongoing-challenges-section">
        <h2 className="ongoing-challenges-title">Explore Upcoming Challenge</h2>
        <div className="challenge-grid">
          <div className="challenge-card challenge-card-1">
            <img
              src={image3}
              alt="Upcoming 1"
              className="challenge-image challenge-image-1"
            />
            <h3 className="challenge-name challenge-name-1">Competitor Info</h3>
            <p className="challenge-description challenge-description-1">
              Lorem ipsum dolor sit amet consectetur. Hac enim lacus semper
              magna.
            </p>
          </div>

          <div className="challenge-card challenge-card-2">
            <img
              src={image2}
              alt="Upcoming 2"
              className="challenge-image challenge-image-2"
            />
            <h3 className="challenge-name challenge-name-2">Competitor Info</h3>
            <p className="challenge-description challenge-description-2">
              Lorem ipsum dolor sit amet consectetur. Hac enim lacus semper
              magna.
            </p>
          </div>

          <div className="challenge-card challenge-card-3">
            <img
              src={image1}
              alt="Upcoming 3"
              className="challenge-image challenge-image-3"
            />
            <h3 className="challenge-name challenge-name-3">Competitor Info</h3>
            <p className="challenge-description challenge-description-3">
              Lorem ipsum dolor sit amet consectetur. Hac enim lacus semper
              magna.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AIChallenge;
