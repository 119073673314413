import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PostCarousel } from "../../components/Carousel";
import Gravatar from "react-gravatar";
import CryptoJS from "crypto-js";
import { MdDelete, MdLoop } from "react-icons/md";
import logo_imgs from "../../assets/mainLogo-transformed.webp";
import { ToggleButton } from "../../components/UIcomponents/ToggleButton";
import { ImEyeBlocked, ImEye } from "react-icons/im";
import { PostFooter } from "../Components/PostFooter";
import { ReportModal } from "../../components/ReportModal";
import { FaFlag } from "react-icons/fa";

export const SharedPost = ({
  post,
  index,
  width,
  saved = false,
  inProfile,
  onDelete,
}) => {
  const username = useSelector((state) => state.user.userName);
  const [reveal, setReveal] = useState(false);
  const hash = CryptoJS.MD5(username).toString();
  const regex = /@\[([^\]]+)\]\(([^)]+)\)/g;
  const parts = post?.caption?.split(regex);
  const originalParts = post?.originalPost?.caption?.split(regex);
  const navigate = useNavigate();
  const [selectedPost, setSelectedPost] = useState(null);

  function formatRelative(date, baseDate) {
    const diffInMs = baseDate - date;
    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""} ago`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    }
  }
  const handleTryPrompt = (prompt) => {
    navigate("/", { state: prompt });
  };

  const handleReport = () => {
    setSelectedPost(post._id);
  };

  const styles = {
    borderRadius: "10px",
    ...(width && { width: `${width}%` }),
  };

  const AiPost = ({ originalPost }) => {
    return (
      <>
        {reveal && (
          <div className="w-full px-4 my-2 flex  justify-center items-center">
            <div className="w-full min-h-[10vh] gap-2 p-2 py-4 flex justify-center items-center rounded-lg border-[2px] border-[#797986] flex-col">
              <p className="text-[#cdcdcd] ">
                {originalPost?.conversation?.[0].prompt}
              </p>

              <button
                className="followusm"
                onClick={() =>
                  handleTryPrompt(originalPost?.conversation?.[0].prompt)
                }
              >
                Try Prompt
              </button>
            </div>
          </div>
        )}
        <div className="image-grid">
          {originalPost?.conversation[0]?.mediaUrls && (
            <PostCarousel
              mediaUrls={originalPost?.conversation[0]?.mediaUrls}
            />
          )}
        </div>
      </>
    );
  };

  const ConversationPost = ({ originalPost }) => {
    return (
      <div className="w-full md:w-[95%] bg-[#0d1525] max-h-[58vh] overflow-y-auto md:p-4 rounded-lg mx-auto">
        {originalPost.conversation?.map((chat, id) => (
          <div className=" h-auto gap-1 text-white" key={id}>
            <div className="p-2 px-4 flex flex-col justify-start items-start">
              <div className="flex justify-start items-center gap-4">
                {originalPost?.profile?.imageUrl ? (
                  <img
                    src={originalPost.profile.imageUrl}
                    alt="profile"
                    className="user_frien3"
                    style={{ width: "34px" }}
                  />
                ) : (
                  <Gravatar
                    email={originalPost?.username}
                    size={150}
                    default="identicon"
                    className="user_frien3"
                    style={{ width: "2rem", height: "2rem" }}
                    hash={hash}
                  />
                )}
                <h3 className=" text-[18px] font-bold ">
                  {originalPost.username}
                </h3>
              </div>
              <div>
                <p className=" pl-[3rem] text-base font-normal">
                  {chat.prompt}
                </p>
              </div>
            </div>
            <div className="p-2 px-4 flex flex-col justify-start items-start">
              <div className="flex justify-start items-center gap-1">
                <div className="w-[2.5rem] h-[2rem] pl-[-0.5rem] rounded-full object-cover">
                  <img src={logo_imgs} alt="boy" className="h-full w-full" />
                </div>

                <h3 className=" text-[18px] font-bold ">YouAI</h3>
              </div>
              <div>
                <p className=" pl-[3rem] text-base font-normal">
                  {chat.response}
                  {chat.mediaUrls &&
                    (chat.mediaUrls[0]?.type === "image" ? (
                      <img
                        src={chat.mediaUrls[0]?.url}
                        alt={chat.mediaUrls[0]?.type}
                      />
                    ) : chat.mediaUrls[0]?.type === "video" ? (
                      <video
                        className="video-player"
                        src={chat.mediaUrls[0]?.url}
                        alt={"video"}
                        controls
                      />
                    ) : (
                      <audio src={chat.mediaUrls[0]?.url} alt="audio" />
                    ))}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const NormalPost = ({ originalPost }) => {
    return (
      <div className="image-grid">
        {originalPost?.mediaUrls && (
          <PostCarousel mediaUrls={originalPost?.mediaUrls} />
        )}
      </div>
    );
  };

  return (
    <div className="mt-[10px]">
      <div className="post_displaying" style={width && { width: `${width}%` }}>
        <div className="post_person">
          <div className="post_img_name">
            {post.profile?.imageUrl ? (
              <img
                src={post.profile.imageUrl}
                alt="boy"
                className="post_own_profile"
              />
            ) : (
              <Gravatar
                email={post.username}
                size={150}
                default="identicon"
                className="user_frien3"
                hash={hash}
              />
            )}

            <div className="name_time">
              <div className="flex gap-2 justify-center items-center">
                <Link to={`/social_profile/${post.username}`}>
                  <p className="name_postig">{post.username}</p>
                </Link>{" "}
                <div className="flex gap-1 text-sm text-gray-400">
                  <MdLoop /> Reposted
                </div>
              </div>
              <p className="time_post">
                {post.createdAt
                  ? formatRelative(new Date(post.createdAt), new Date())
                  : "now"}
              </p>
            </div>
          </div>
          {inProfile ? (
            <MdDelete
              onClick={onDelete}
              size={"1.25rem"}
              cursor={"pointer"}
              color="rgb(107 114 128)"
            />
          ) : (
            <FaFlag
              cursor={"pointer"}
              onClick={handleReport}
              size={"1.05rem"}
              color="#cdcdcd"
            />
          )}
        </div>
        <p className="posttweet mb-4">
          {parts?.map((part, index) => {
            if (index % 3 === 0) {
              // Regular text between tagged users
              return <span key={index}>{part}</span>;
            } else if (index % 3 === 1) {
              // Username (display name)
              const username = part;
              return (
                <span
                  key={index}
                  onClick={() => navigate(`/social_profile/${username}`)}
                  className="font-bold cursor-pointer"
                >
                  @{username}
                </span>
              );
            } else if (index % 3 === 2) {
              return null; // Optional: render user ID
            }
          })}
        </p>
        <div
          className="post_displaying border-[1px] border-[#4A5056]"
          style={styles}
        >
          <div className="post_person">
            <div className="post_img_name">
              {post.originalPost.profile?.imageUrl ? (
                <img
                  src={post.originalPost.profile.imageUrl}
                  alt="boy"
                  className="post_own_profile"
                />
              ) : (
                <Gravatar
                  email={post.originalPost.username}
                  size={150}
                  default="identicon"
                  className="user_frien3"
                  hash={hash}
                />
              )}

              <div className="name_time">
                <Link to={`/social_profile/${post.originalPost.username}`}>
                  <p className="name_postig">{post.originalPost.username}</p>
                </Link>
                <p className="time_post">
                  {post.originalPost.createdAt
                    ? formatRelative(
                        new Date(post.originalPost.createdAt),
                        new Date()
                      )
                    : "now"}
                </p>
              </div>
            </div>
            {post.originalPost.type === "ai" && (
              <div className="flex gap-2 justify-center items-center">
                <ToggleButton
                  offIcon={ImEyeBlocked}
                  onIcon={ImEye}
                  active={reveal}
                  setActive={setReveal}
                />
              </div>
            )}
          </div>

          <p className="posttweet mb-4">
            {originalParts?.map((part, index) => {
              if (index % 3 === 0) {
                return <span key={index}>{part}</span>;
              } else if (index % 3 === 1) {
                const username = part;
                return (
                  <span
                    key={index}
                    onClick={() => navigate(`/social_profile/${username}`)}
                    className="font-bold cursor-pointer"
                  >
                    @{username}
                  </span>
                );
              } else if (index % 3 === 2) {
                return null;
              }
            })}
          </p>
          {post.originalPost.type === "normal" ? (
            <NormalPost originalPost={post.originalPost} />
          ) : post.originalPost.type === "ai" ? (
            <AiPost originalPost={post.originalPost} />
          ) : (
            <ConversationPost originalPost={post.originalPost} />
          )}
        </div>

        <PostFooter post={post} index={index} saved={saved} />
      </div>
      {selectedPost ? (
        <ReportModal
          id={selectedPost}
          isOpen={selectedPost}
          onRequestClose={() => setSelectedPost(null)}
          content={"post"}
        />
      ) : null}
    </div>
  );
};
