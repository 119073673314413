import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { PostCarousel } from "../../components/Carousel";
import Gravatar from "react-gravatar";
import CryptoJS from "crypto-js";
import { ImInfo } from "react-icons/im";
import { PostFooter } from "../Components/PostFooter";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { GiTrophyCup } from "react-icons/gi";
import { setChallengeId } from "../../features/aiChallengeSlice"; // Import action

export const PostWidgetAIChallenge = ({
  post,
  index,
  width,
  saved = false,
  inProfile,
  onDelete,
}) => {
  const username = useSelector((state) => state.user.userName);

  const hash = CryptoJS.MD5(username).toString();
  const regex = /@\[([^\]]+)\]\(([^)]+)\)/g;
  const parts = post?.caption?.split(regex);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  function formatRelative(date, baseDate) {
    const diffInMs = baseDate - date;
    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""} ago`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    }
  }

  const handleGenerateNow = () => {
    console.log("PostWidgetAIChallenge: handleGenerateNow: post._id", post._id);
    // Dispatch challenge ID to Redux store
    dispatch(setChallengeId(post._id));

    // Navigate to the AI chat challenge route
    navigate("/aichat/aichallenge");
  };

  return (
    <div className="mt-[10px]">
      <div className="post_displaying" style={width && { width: `${width}%` }}>
        <div className="post_person">
          <div className="post_img_name">
            {post.profile?.imageUrl ? (
              <img
                src={post.profile.imageUrl}
                alt="boy"
                className="post_own_profile"
              />
            ) : (
              <Gravatar
                email={post.username}
                size={150}
                default="identicon"
                className="user_frien3"
                hash={hash}
              />
            )}

            <div className="name_time">
              <Link to={`/social_profile/${post.username}`}>
                <p className="name_postig">{post.username}</p>
              </Link>{" "}
              <p className="time_post">
                {post.createdAt
                  ? formatRelative(new Date(post.createdAt), new Date())
                  : "now"}
              </p>
            </div>
          </div>

          <div className="flex gap-2 justify-center items-center">
            <ImInfo
              size="1.5rem"
              color="white"
              cursor={"pointer"}
              title="Interact with this post"
              onClick={() => navigate(`/social/interact/${post?._id}`)}
            />
            {inProfile && (
              <MdDelete
                onClick={onDelete}
                size={"1.5rem"}
                cursor={"pointer"}
                color="rgb(107 114 128)"
              />
            )}
          </div>
        </div>

        <p className="posttweet mb-4">
          {parts?.map((part, index) => {
            if (index % 3 === 0) {
              // Regular text between tagged users
              return <span key={index}>{part}</span>;
            } else if (index % 3 === 1) {
              // Username (display name)
              const username = part;
              return (
                <span
                  key={index}
                  onClick={() => navigate(`/social_profile/${username}`)}
                  className="font-bold cursor-pointer"
                >
                  @{username}
                </span>
              );
            } else if (index % 3 === 2) {
              return null; // Optional: render user ID
            }
          })}
        </p>
        <div className="image-grid">
          {post?.mediaUrls && <PostCarousel mediaUrls={post?.mediaUrls} />}
        </div>

        <PostFooter post={post} index={index} saved={saved} />
        <div className="p-2 md:p-3 pt-0 md:pt-0">
          <div className="bg-[#0D1525] rounded-b-[10px] md:rounded-b-[20px] p-3 md:px-6 flex justify-between items-center">
            <h3 className="flex text-white text-sm md:text-[18px]">
              {" "}
              <GiTrophyCup className="trophycup" />
              AI Challenge
            </h3>
            <button
              onClick={handleGenerateNow}
              className="p-2 rounded-md border-[1px] border-white text-sm text-white font-semibold md:px-6 bg-[#1B2333]"
            >
              Start
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
