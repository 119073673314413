const basic_plan = {
  name: "Basic",
  monthlyPrice: 10,
  yearlyPrice: 50,
  features: [
    "Personalization (YouAi + Prompt)",
    "Access to 20+ AI Models (Limited Access)",
    "GPT4 (Limited Access)",
    "Code Interpreter (Limited Access)",
    "Voice Assistant (Limited Access)",
    "GPT4 Vision (Limited Access)",
  ],
  monthlyCredits: 50,
  yearlyCredits: 200,
};
const pro_plan = {
  name: "Pro",
  monthlyPrice: 25,
  yearlyPrice: 100,
  features: [
    "Multimodal Response",
    "Personalization (YouAi + Prompt)",
    "Avatar Generation",
    "GPT4 (Full Access)",
    "Code Interpreter (Full Access)",
    "Voice Assistant (Full Access)",
    "GPT4 Vision (Full Access)",
  ],
  monthlyCredits: 150,
  yearlyCredits: 300,
};
const pro_plus_plan = {
  name: "Pro Plus",
  monthlyPrice: 50,
  yearlyPrice: 250,
  features: [
    "Personalization (YouAi + Prompt)",
    "Avatar Generation",
    "Flux Model",
    "GPT4 (Full Access)",
    "Code Interpreter (Full Access)",
    "Voice Assistant (Full Access)",
    "GPT4 Vision (Full Access)",
  ],
  monthlyCredits: 300,
  yearlyCredits: 600,
};
const test_plan = {
  name: "Test Plan",
  monthlyPrice: 1,
  yearlyPrice: 2,
  features: [
    "Personalization (YouAi + Prompt)",
    "Avatar Generation",
    "Flux Model",
    "GPT4 (Full Access)",
    "Code Interpreter (Full Access)",
    "Voice Assistant (Full Access)",
    "GPT4 Vision (Full Access)",
  ],
  monthlyCredits: 100,
  yearlyCredits: 500,
};

let plans = [basic_plan, pro_plan, pro_plus_plan];

// If on test mode, add test_plan in plans array to test payments
plans = [...plans, test_plan];
export default plans;
