import React, { useEffect, useState, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player";
import "../styles/socialFeatures/PostWidgetProfile.css";

export const PostCarousel = ({ mediaUrls }) => {
  const [mediaData, setMediaData] = useState([]);
  const playerRefs = useRef([]);

  useEffect(() => {
    if (mediaUrls) {
      const fetchMediaData = async () => {
        const promises = mediaUrls?.map((media) => {
          return new Promise((resolve) => {
            const mediaType = media.type;
            if (mediaType === "image") {
              const img = new Image();
              img.src = media.url;
              img.onload = () => {
                resolve({
                  url: media.url,
                  type: mediaType,
                  width: img.width,
                  height: img.height,
                });
              };
              img.onerror = () => {
                resolve({ url: media.url, type: mediaType, error: true });
              };
            } else {
              resolve({ url: media.url, type: mediaType });
            }
          });
        });

        const results = await Promise.all(promises);
        setMediaData(results);
      };
      fetchMediaData();
    }
  }, [mediaUrls]);

  useEffect(() => {
    const observers = playerRefs.current.map((playerRef, index) => {
      if (!playerRef) return null;

      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && mediaData[index].type === "video") {
            playerRef.seekTo(0); // Seek to start if needed
            playerRef.getInternalPlayer().play();
            playerRef.getInternalPlayer().style.pointerEvents = "auto";
          } else if (mediaData[index].type === "video") {
            playerRef.getInternalPlayer().pause();
            playerRef.getInternalPlayer().style.pointerEvents = "none";
          }
        },
        {
          root: null, // Use the viewport as the container
          rootMargin: "0px",
          threshold: 0.5, // Trigger when 50% of the video is in view
        }
      );

      const element = playerRef.getInternalPlayer(); // Get the internal video element
      if (element instanceof Element) {
        observer.observe(element);
      }

      return observer;
    });

    // Cleanup observers on unmount
    return () => {
      observers.forEach((observer) => {
        if (observer) observer.disconnect();
      });
    };
  }, [mediaData]);

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      showArrows={true}
      showIndicators={mediaData?.length !== 1}
      emulateTouch={true}
      swipeable={true}
      dynamicHeight={true}
    >
      {mediaData?.map((media, index) => (
        <div
          key={index}
          className={`postimgags-container ${media.type === "video" ? "vid" : ""}`}
        >
          {media.type === "video" ? (
            <ReactPlayer
              ref={(ref) => (playerRefs.current[index] = ref)}
              url={media.url}
              width="100%"
              height="100%"
              controls={true}
              playing={true} // Autoplay the video
              muted // Keep the video muted
              loop={true} // Loop the video
            />
          ) : (
            <img
              src={media.url}
              alt={`Selected ${index + 1}`}
              className="postimgags"
            />
          )}
        </div>
      ))}
    </Carousel>
  );
};

export default PostCarousel;
