import React, { useState } from "react";
import Modal from "react-modal";
import baseUrl from "../baseUrl";
import { FullScreenLoader } from "./FullScreenLoader";
import { toast } from "react-toastify";
import apiService from "../services/apiService";
import { IoIosCloseCircleOutline } from "react-icons/io";
Modal.setAppElement("#root");

export const ReportModal = ({ id, content, isOpen, onRequestClose, index }) => {
  const [isLoading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  let apiBody = {};
  let url = "";
  switch (content) {
    case "post":
      url = `${baseUrl}/common/report-post`;
      apiBody = {
        postId: id,
        comment,
      };
      break;
    case "aiContent":
      url = `${baseUrl}/common/report-aiChat`;
      apiBody = {
        sessionId: id,
        index,
        comment,
      };
      break;
    default:
      apiBody = { comment };
      break;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!comment) {
        setUploadStatus("Drop your reason for reporting");
        return;
      }
      console.log(apiBody);
      const response = await apiService.post(url, apiBody);

      if (response) {
        toast.success(response);
        onRequestClose();
      }
    } catch (error) {
      toast.error(`Something went wrong`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 flex items-center justify-center p-2 h-[50vh] w-1/3 rounded-lg shadow-lg bg-white bg-opa"
      overlayClassName="fixed inset-0 bg-black bg-opacity-65 backdrop-blur-sm"
    >
      <div className="relative flex flex-col w-full h-full p-4 bg-[#1b2333] border-2 rounded-lg">
        <button
          onClick={onRequestClose}
          className="absolute top-2 right-3 p-2 text-[#cdcdcd] text-lg font-bold"
        >
          <IoIosCloseCircleOutline className="text-[#cdcdcd]" />
        </button>
        <div
          className={`flex flex-col items-center justify-between w-full h-full p-4 gap-4 rounded-lg cursor-pointer transition-colors`}
        >
          <h2 className="text-base font-semibold text-white">
            Found this content inappropriate?
          </h2>
          <textarea
            placeholder="Enter the reason for reporting this content"
            value={comment}
            rows={6}
            onChange={(e) => setComment(e.target.value)}
            className="w-full p-4 text-white bg-transparent outline-none border-2 rounded-xl "
          ></textarea>
          {uploadStatus && (
            <p className="text-white text-base ">{uploadStatus}</p>
          )}

          <div className="flex w-full gap-8 justify-between items-center">
            <button
              className="w-1/2 p-2 rounded-xl bg-transparent border-2 border-[#767575] text-[#767575]"
              onClick={onRequestClose}
            >
              Cancel
            </button>
            <button
              className="w-1/2 p-2 rounded-xl bg-red-600 text-white"
              onClick={handleSubmit}
            >
              Submit Report
            </button>
          </div>
        </div>
      </div>
      {isLoading ? <FullScreenLoader /> : null}
    </Modal>
  );
};
