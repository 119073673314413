import React from "react";
import { HashLoader } from "react-spinners";

export const FullScreenLoader = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-screen bg-[#000000d1] bg-opacity-50 flex justify-center items-center z-[1000]">
      <HashLoader color="#6cd97e" size={50} />
    </div>
  );
};
