import { ArrowLeft } from "react-feather";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReportDisplay = ({ report, onReset }) => {
  const handleCopyToClipboard = async () => {
    try {
      const reportText = Object.entries(report)
        .map(([key, value]) => `${key}:\n${value}`)
        .join("\n\n");
      await navigator.clipboard.writeText(reportText);
      toast.success("Report copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy text: ", err);
      toast.error("Failed to copy report to clipboard.");
    }
  };

  const formattedReport = Object.entries(report).map(([key, value], index) => (
    <div key={index}>
      <h2
        style={{
          fontWeight: "bold",
          fontSize: "18px",
          color: "#6cd97e",
          marginTop: "20px",
        }}
      >
        {key.replace(/([A-Z])/g, " $1").trim()}
      </h2>
      <p style={{ fontSize: "16px", whiteSpace: "pre-wrap" }}>{value}</p>
    </div>
  ));

  return (
    <div className="rounded-lg shadow-lg lg:max-w-full md:max-w-full max-w-lg w-full mx-auto text-white p-10">
      <div className="flex justify-between items-center">
        <button
          onClick={onReset}
          className="bg-transparent text-gray-200 hover:text-gray-400"
        >
          <ArrowLeft className="h-6 w-6 mr-3" />
        </button>
        <button
          onClick={handleCopyToClipboard}
          className="text-gray-200 border border-gray-400 hover:bg-gray-400 rounded-lg px-7 py-2"
        >
          Copy
        </button>
      </div>
      {formattedReport}
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ReportDisplay;
