import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoMdAdd, IoMdTrash } from "react-icons/io";
import Popup from "./GlobalAiPopup";
import "../styles/globalAiNews.css";
import apiService from "../services/apiService";
import baseUrl from "../baseUrl";
import { DotLoader, ClipLoader } from "react-spinners";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useSelector } from "react-redux";

const NewsDisplay = () => {
  const [newsData, setNewsData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loadingImage, setLoadingImage] = useState({}); // Track loading images
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [authorizedUser, setAuthorizedUser] = useState(false);
  const username = useSelector((state) => state.user.userName);

  useEffect(() => {
    checkUserAuthorization();
  }, []);

  const checkUserAuthorization = () => {
    const authorizedUsers = ["hanishols"];
    if (authorizedUsers.includes(username)) {
      setAuthorizedUser(true);
    }
  };

  const fetchData = async (page) => {
    setIsLoading(true);
    try {
      const response = await apiService.get(
        `${baseUrl}/ai/getAiNews?page=${page}`
      );
      setNewsData(response.articles);
      setTotalPages(response.totalPages);

      // Preload images early
      const loadingImages = {};
      response.articles.forEach((news) => {
        loadingImages[news._id] = true;
        preloadImage(news.image, news._id);
      });
      setLoadingImage(loadingImages);
    } catch (error) {
      console.error("Failed to fetch news data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const preloadImage = (src, id) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setLoadingImage((prev) => ({
        ...prev,
        [id]: false, // Mark image as loaded
      }));
    };
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);

  const handleAddNews = (news) => {
    setNewsData([news, ...newsData]);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const truncateText = (text, limit) => {
    if (text.length <= limit) return text;
    return `${text.substring(0, limit)}...`;
  };

  const stripHtmlTags = (text) => {
    const div = document.createElement("div");
    div.innerHTML = text;
    return div.textContent || div.innerText || "";
  };

  const handleDeleteClick = () => {
    setIsSelecting(!isSelecting);
    setSelectedArticles([]);
  };

  const handleArticleSelect = (id) => {
    if (selectedArticles.includes(id)) {
      setSelectedArticles(
        selectedArticles.filter((articleId) => articleId !== id)
      );
    } else {
      setSelectedArticles([...selectedArticles, id]);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      await apiService.post(`${baseUrl}/ai/deleteAiNews`, {
        ids: selectedArticles,
      });
      setNewsData(
        newsData.filter((news) => !selectedArticles.includes(news._id))
      );
      setSelectedArticles([]);
      setIsSelecting(false);
    } catch (error) {
      console.error("Failed to delete articles", error);
    }
  };

  return (
    <div className="news-container">
      <header className="news-header">
        <h1 className="header-title">Global AI News</h1>
        <p className="header-subtitle">Model for generating text to image</p>
      </header>

      {isLoading ? (
        <div className="loader-container1">
          <ClipLoader size={50} color={"#6cd97e"} loading={isLoading} />
        </div>
      ) : (
        <>
          <div className="news-list">
            {authorizedUser && (
              <div className="admin-buttons">
                <button
                  className="admin-button add-button"
                  onClick={() => setIsPopupOpen(true)}
                >
                  <IoMdAdd /> Add
                </button>
                <button
                  className={`admin-button delete-button ${
                    isSelecting ? "selecting" : ""
                  }`}
                  onClick={handleDeleteClick}
                >
                  <IoMdTrash /> {isSelecting ? "Cancel" : "Delete"}
                </button>
                {isSelecting && (
                  <button
                    className="admin-button confirm-delete-button"
                    onClick={handleDeleteSelected}
                  >
                    Confirm Delete
                  </button>
                )}
              </div>
            )}
            {newsData.map((news) => (
              <div
                className={`news-item ${
                  isSelecting ? "selectable" : ""
                } ${selectedArticles.includes(news._id) ? "selected" : ""}`}
                key={news._id}
                onClick={() => isSelecting && handleArticleSelect(news._id)}
              >
                {loadingImage[news._id] ? (
                  <div className="image-loader">
                    <DotLoader size={50} color={"#6cd97e"} />
                  </div>
                ) : (
                  <img
                    src={news.image}
                    alt={news.title}
                    className="news-image"
                  />
                )}
                <div className="news-content">
                  <h2 className="news-title">{news.title}</h2>
                  <p className="news-description">
                    {truncateText(stripHtmlTags(news.content), 200)}
                  </p>
                  <Link
                    to={`/globalAiNewsDetail/${news._id}`}
                    className="read-more"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination-controls">
            <button
              className="pagination-button"
              onClick={handlePreviousPage}
              disabled={page === 1}
            >
              <FaArrowLeft />
            </button>
            <button
              className="pagination-button"
              onClick={handleNextPage}
              disabled={page === totalPages}
            >
              <FaArrowRight />
            </button>
          </div>
        </>
      )}

      <Popup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onSave={handleAddNews}
      />
    </div>
  );
};

export default NewsDisplay;
